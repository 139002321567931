import React, {VFC} from 'react'
import {Button, Modal} from 'semantic-ui-react'
import {SimpleBox} from '../../../components/SimpleBox'
import {toMoney} from '../../../shared/format'

const styles = require('./Confirm.module.scss')

type Props = {
  onConfirm: () => void;
  onPayMonthly: () => void;
  onCancel: () => void;
  price: number;
  months: number
};

const Confirm: VFC<Props> = ({onConfirm, onPayMonthly, onCancel, price, months}) => <>
  <Modal.Content>
    <div className={styles.header}>Add VPN+</div>
    <SimpleBox maxWidth={360} mx="auto" display={'flex'} flexDirection={'column'} gap={'30px'}>
      <SimpleBox>
        <span style={{textAlign:'center'}}>By placing this order for VPN Plus, your credit card will be <span className={styles.highlight}>
          charged again for {toMoney(price)} for {months} months</span> of VPN+ extra protection.</span>
      </SimpleBox>
      <SimpleBox flexDirection={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
        <Button onClick={onConfirm} primary className={styles.button} >Confirm</Button>
        <Button onClick={onPayMonthly} secondary className={styles.button}>Pay Monthly</Button>
      </SimpleBox>
      <SimpleBox alignItems={'center'} display={'flex'} flexDirection={'column'}>
        <p>Don&lsquo;t want the extra protection?</p>
        <Button onClick={onCancel} className={styles.skipButton}>Skip for now</Button>
      </SimpleBox>
    </SimpleBox>
  </Modal.Content>
</>

export default Confirm
