import React, { VFC } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { SimpleBox } from '../../../components/SimpleBox'
import { SimpleText } from '../../../components/SimpleText'
import { Coupon } from '../../../utils/coupon'
import { Plan } from '../../../type'

type Props = {
  isOpen: boolean,
  payload?: { coupon: Coupon, plan: Plan }
  onFinish?: (proceed: boolean) => void
}

const DiscountWarning: VFC<Props> = ({ payload, isOpen, onFinish }) => {
  if(!isOpen) {
    return <></>
  }

  return (
    <>
      <Modal.Header className="white">
        <h6 className="title">Warning</h6>
      </Modal.Header>
      <Modal.Content className="white">
        <SimpleBox>
          <SimpleText>The coupon code <b>{payload?.coupon.name?.toLocaleUpperCase()}</b> is not valid with <b>{payload?.plan.externalName}</b>.</SimpleText>
          <br />
          <br />
          <SimpleText>This coupon will be removed if you change your plan.</SimpleText>
        </SimpleBox>
        <SimpleBox mt={10} maxWidth={240} mx="auto">
          <Button
            size="huge"
            fluid
            primary
            color="red"
            onClick={() => { onFinish?.(true) }}
          >
            Continue
          </Button>
        </SimpleBox>
      </Modal.Content>
    </>
  )
}
export { DiscountWarning }
