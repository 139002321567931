import {Coupon} from '../utils/coupon'

export type Currencies = 'USD'

export const DEFAULT_CURRENCY: Currencies = 'USD'
export const LOCALE = 'en'

export function toMoney(value: number, currency: Currencies = DEFAULT_CURRENCY): string {
  const decimal = Number(value).toFixed(2).split('.')[1]
  const _value = (value > 0 && (decimal === '00' || decimal === '50')) ? Math.round(value*2)/2 - 0.01 : value
  return Intl.NumberFormat(LOCALE, { style: 'currency', currency }).format(_value)
}

export function toPercent(value: number, decimals = 0): string {
  return Intl.NumberFormat(LOCALE, {
    style: 'percent',
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(value)
}

/**
 * Returns the price substracted by the coupon if given
 * @param price
 * @param coupon
 */
export const toCouponPrice = (price: number, coupon?: Coupon): number => {
  if (coupon) {
    switch (coupon.discountType) {
      case 'PERCENTAGE':
        return price - (price * coupon.discountPercentage!)
      case 'FIXED_AMOUNT':
        return price - coupon.discountAmount!
      default:
        return price
    }
  } else {
    return price
  }
}
