import {ICardProps} from './ICardProps'
import {useSiteSetting} from '../../hooks/useSiteSetting'
import {AuthorizeNetCard} from './AuthorizeNetCard'
import {Card} from './Card'
import React, {FC} from 'react'

type Props = {
  forceAuthorize?: boolean
}

export const CardComponent: FC<ICardProps & Props> = ({ data, forwardRef, captchaRef, onChange, onErrors, forceAuthorize}) => {
  const authorizeSetting = useSiteSetting('AUTHORIZE_ENABLED')

  if (authorizeSetting.loading) {
    return <></>
  }

  const authorizeEnabled = forceAuthorize === true || authorizeSetting.enabled

  return authorizeEnabled ? (
    <AuthorizeNetCard
      data={data}
      forwardRef={forwardRef}
      captchaRef={captchaRef}
      onChange={onChange}
      onErrors={onErrors}
    />
  ) :
    <Card
      data={data}
      forwardRef={forwardRef}
      captchaRef={captchaRef}
      onChange={onChange}
      onErrors={onErrors}
    />
}
