import { classNames, variationName } from '@shopify/css-utilities'
import React, { CSSProperties, FC } from 'react'
import { Content, Header, HeaderProps } from './components'
const styles = require('./SimpleCard.module.scss')

export type CardPadding = 'subtile' | 'heavy'
type Comps = { Header: typeof Header; Content: typeof Content }
type Props = {
  fluid?: boolean
  className?: string
  color?: 'subdued' | 'grey' | 'white' | 'florestlight'
  status?: 'info' | 'success' | 'error' | 'warning'
  rounded?: 'subtile' | 'heavy'
  padding?: CardPadding
  outline?: boolean
  base?: boolean
  backgroundImage?: string
  backgroundColor?: CSSProperties['backgroundColor']
  backgroundPosition?: CSSProperties['backgroundPosition']
  backgroundSize?: CSSProperties['backgroundSize']
  bottomGutter?: boolean
  style?: CSSProperties
} & HeaderProps
const SimpleCard: FC<Props> & Comps = ({
  className,
  color,
  fluid,
  status,
  children,
  rounded,
  padding,
  outline,
  base,
  // header
  title,
  action,
  backgroundColor,
  backgroundImage,
  backgroundPosition,
  backgroundSize,
  bottomGutter,
  style,
}) => {
  const styleCard = classNames(
    styles.Card,
    fluid && styles.Fluid,
    color && !backgroundColor && styles[variationName('color', color)],
    status && styles[variationName('status', status)],
    rounded && styles[variationName('rounded', rounded)],
    padding && styles[variationName('padding', padding)],
    outline ? styles.Outline : undefined,
    bottomGutter ? styles.BottomGutter : undefined,
    className
  )

  const header = title && <Header title={title} action={action} />
  const content = base ? children : <Content>{children}</Content>

  return (
    <div
      className={styleCard}
      style={{
        backgroundColor,
        backgroundImage: backgroundImage ? `url('${backgroundImage}')` : undefined,
        backgroundSize,
        backgroundPosition,
        ...style,
      }}
    >
      {header}
      {content}
    </div>
  )
}

SimpleCard.defaultProps = { color: 'white' }

SimpleCard.Header = Header
SimpleCard.Content = Content

export { SimpleCard }
