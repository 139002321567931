/* eslint-disable max-len */
import React, { useState, FC, useEffect } from 'react'
import { Addon, Addons } from '../pages/welcome'
import {Segment, Header, Label, Button, Modal} from 'semantic-ui-react'
import { toPlanPeriod } from '../utils'
// @ts-ignore
import { toMoney } from '~shared/format'
// @ts-ignore
import Mutation from '~shared/mutation'
// @ts-ignore
import Notifications from '~shared/notifications'
import './addon-promotion.scss'
import {Coupon, CouponDiscount, FetchCoupon} from '../utils/coupon'
import Router from '../shared/router'
import {SimpleBox} from '../components/SimpleBox'
import Confirm from './modal/vpn-plus/Confirm'
import {trackOneClickPurchase} from '../utils/tracking'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const IconWhite = require('~assets/images/icon-white.svg')

/// Id use for monthly vpn plus addons (we add them as sa subscription instead as addon)
const VPN_PLUS_MONTHLY_ID = process.env.VPN_PLUS_MONTHLY_ID

function importAll(
  ctx: __WebpackModuleApi.RequireContext
): Record<string, any> {
  const images: Hash = {}
  ctx.keys().forEach(item => {
    images[item.replace('./', '')] = ctx(item)
  })
  return images
}

function calculatePeriod(period: number, periodUnit: string): number {
  return periodUnit === 'YEAR' ? 12 * period : period
}

function prices(item: Addon, coupon?: Coupon): [number, number, number] {
  if (coupon) {
    switch (coupon.discountType) {
      case 'FIXED_AMOUNT':
        return [
          (item.price - (coupon.discountAmount || 0)) / item.period,
          item.price,
          item.price - (coupon.discountAmount || 0),
        ]
      case 'PERCENTAGE':
        return [
          item.price / item.period -
            (item.price / item.period) * (coupon.discountPercentage || 0),
          item.price,
          item.price - item.price * (coupon.discountPercentage || 0),
        ]
    }
  }

  return [item.price / item.period, item.price, item.price]
}

const images = importAll(
  require.context('../assets/images/addon', false, /\.svg$/)
)

type Props = { data: Addons; toggle(): void };

const texts = [
  'Block ads at the network level to get get faster load times, increased battery life, all while using less mobile data.',
  'Automatically block malicious scripts and trackers from ever loading on your device.',
  'Access international content without geo-restrictions and other limitations.',
  'As a priority support customer, your phone call skips the line, no matter what the hold times.',
  'Access a dedicated network of low-latency, high-performance servers designed for gaming.',
  'Hide the fact that you\'re using a VPN, allowing you complete & unlimited anonymous access to the internet.',
  'Connect to two locations at once to give yourself double the VPN protection.',
]

type Advantage = { icon: string; color: string; title: string; text: string };
const advantages: Advantage[] = [
  {
    icon: 'no-ads',
    color: 'rgba(0, 71, 255, 0.2)',
    title: 'Ad-Blocking',
    text: texts[0],
  },
  {
    icon: 'no-malware',
    color: 'rgba(173, 0, 255, 0.2)',
    title: 'Malware Blocking',
    text: texts[1],
  },
  {
    icon: 'monitoring',
    color: 'rgb(213, 237, 197)',
    title: 'Identity Protection',
    text: 'Our advanced Identity Theft Protection suite provides you 24/7 peace of mind and protection.'
  },
  {
    icon: 'streaming',
    color: 'rgba(26, 178, 51, 0.2)',
    title: 'Premium Unrestricted Streaming',
    text: texts[2],
  },
  {
    icon: 'mobile',
    color: 'rgba(0, 174, 153, 0.2)',
    title: 'Priority phone support',
    text: texts[3],
  },
  {
    icon: 'gaming',
    color: 'rgba(251, 0, 35, 0.2)',
    title: 'Gaming Mode (Coming Soon)',
    text: texts[4],
  },
  {
    icon: 'vpn',
    color: 'rgba(255, 153, 0, 0.2)',
    title: 'Invisible VPN (Coming Soon)',
    text: texts[5],
  },
  {
    icon: 'double-layer',
    color: 'rgba(5, 1, 175, 0.2)',
    title: 'Double VPN (Coming Soon)',
    text: texts[6],
  },
]

type Option = { title: string; bg: string; color: string };
const selectOptions: Record<number, Option> = {
  1: {
    title: 'Monthly',
    bg: '#F5F6F9',
    color: '#787E8D',
  },
  12: {
    title: 'Annual',
    bg: 'rgba(105, 138, 255, 0.15)',
    color: '#698AFF',
  },
  24: {
    title: '2 Years',
    bg: '#1AB233',
    color: '#FFFFFF',
  },
}

type MutationResult = {
  attachAddon?: boolean
  subscribePlan?: boolean
}

const AddonPromotion: FC<Props> = ({ data, toggle }) => {
  const [modal, setModal] = useState<'confirm' | null>(null)
  const subscription = data.account.subscriptions[0]
  const activateNow = Router.qs.activateNow
  const { period, periodUnit } = subscription.itemPrices[0]
  const basePeriod = calculatePeriod(period, periodUnit)
  const allowedPeriods = [1, subscription.itemPrices[0].period]
  const options: Record<number, Addon> = data.addons.filter(addon => allowedPeriods.includes(addon.period)).reduce(
    (obj, item) => ({
      ...obj,
      [calculatePeriod(item.period, item.periodUnit)]: item,
    }),
    {}
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [selected, setSelected] = useState<number>(1)
  const cookieCoupon = Router.qs.coupon || cookies.get('coupon')

  const [coupon, setCoupon] = useState<Coupon>()

  const [,, selectedDiscountedTotalPrice] = prices(options[selected], coupon)

  const mutation = new Mutation<MutationResult>(`
    mutation($subscription: ID!, $id: ID!, $coupon: string) { attachAddon(subscription: $subscription, id: $id, coupon: $coupon) }
  `)

  const vpnPlusMonthlyMutation = new Mutation<MutationResult>(`
    mutation($input: SubscribePlanInput!) { subscribePlan(input: $input) }
  `)

  useEffect(() => {
    const option = options[selected]
    if (option && cookieCoupon) {
      FetchCoupon(option.id, cookieCoupon)
        .then(loadedCoupon => {
          setCoupon(loadedCoupon)
        })
        .catch(error => {
          Notifications.error(error || 'Unable to add coupon')
          setCoupon(undefined)
        })
    }
  }, [cookieCoupon, selected])

  const attachAddon = () => {
    if (loading) {
      return
    }

    setLoading(true)

    const option = options[selected]

    let attachMutation: Mutation<MutationResult>
    let args: Hash
    if (option.period === 1) {
      args = { input: { planId: VPN_PLUS_MONTHLY_ID, activateNow: activateNow || false, coupon: coupon?.id || '' } }
      attachMutation = vpnPlusMonthlyMutation
    } else {
      args = { subscription: subscription.id, id: option.id, coupon: coupon?.id || '' }
      attachMutation = mutation
    }

    attachMutation.exec(args).then(() => {
      if (attachMutation.data?.attachAddon || attachMutation.data?.subscribePlan) {
        Notifications.success('VPN Plus successfully added!')
        toggle()
      } else {
        Notifications.error(attachMutation.error() || 'Unable to add VPN Plus')
      }

      trackOneClickPurchase(option, coupon)
      setLoading(false)
    })
  }


  const renderCaptionText = (className: string) => <div id={className} className={className}>
    <p>
      {activateNow
        ? 'This add-on expands your protection by including useful features like Ad-Blocking, Malware Blocking, Premium Unrestricted Streaming, Invisible VPN, Double VPN, Gaming Mode and more...' :
        'Get a special offer on our VPN Plus Add-on that expands your protection by including useful features like Ad-Blocking, Malware Blocking, Premium Unrestricted Streaming, Invisible VPN, Double VPN, Gaming Mode and more!'
      }
    </p>
  </div>

  const renderAdvantage = (item: Advantage) => (
    <li key={item.icon}>
      <Header as="h5">
        <figure style={{ backgroundColor: item.color }}>
          {images[`${item.icon}.svg`] && (
            <img src={images[`${item.icon}.svg`]} />
          )}
        </figure>
        {item.title}
      </Header>
      <p>{item.text}</p>
    </li>
  )

  const renderAddonPrice = (item: Addon) => {
    const [monthlyPrice, totalPrice, discountedTotalPrice] = prices(
      item,
      coupon
    )

    return (
      <Segment
        id="addon"
        className={`${item.periodUnit === 'MONTH' ? 'simple' : ''}`}
        inverted
      >
        <figure>
          <img src={IconWhite} />
        </figure>
        <Header as="h2">VPN Plus</Header>
        {coupon && (
          <Label color="green" size="huge">
            {CouponDiscount(coupon)} OFF
          </Label>
        )}
        <div className="banner">
          {coupon && <>COUPON APPLIED<br/><b>{coupon.name}</b></>}
          {!coupon && 'SELECTED PLAN'}
        </div>
        <div className="monthly">
          <span>{toMoney(monthlyPrice)}</span>
          <span>{toMoney(monthlyPrice)}</span>
          <small>/monthly</small>
        </div>
        <div className="total">
          Billed {toPlanPeriod(item)}
          {totalPrice !== discountedTotalPrice && ' - '}
          <span>
            {totalPrice !== discountedTotalPrice && `${toMoney(totalPrice)}`}
          </span>
          <span>{toMoney(discountedTotalPrice)}</span>
        </div>
      </Segment>
    )
  }

  const renderValueOption = (itemPeriod: number) => {
    if (itemPeriod > basePeriod || !selectOptions[itemPeriod]) {
      return
    }

    const option = selectOptions[itemPeriod]
    const marked = Number(itemPeriod) === selected

    return (
      <li
        key={itemPeriod}
        onClick={() => setSelected(Number(itemPeriod))}
        className={`${marked ? 'selected' : ''}`}
      >
        <b>{option.title}</b>
      </li>
    )
  }

  const menuOptions = Object.keys(options)

  return (<>
    <Modal size={'mini'} closeIcon open={modal === 'confirm'} onClose={() => setModal(null)}>
      <Confirm
        price={selectedDiscountedTotalPrice}
        months={selected}
        onConfirm={() => {
          setModal(null)
          attachAddon()
        }}
        onCancel={() => {
          setModal(null)
          toggle()
        }}
        onPayMonthly={() => {
          setSelected(1)
          setModal(null)
        }} />
    </Modal>
    <section id="addon-promotion">
      <Header as="h2">
        Wait!
        <br />
        Before you continue...
      </Header>
      {renderCaptionText('action-text-mobile')}
      <ul id="advantages">{advantages.map(item => renderAdvantage(item))}</ul>
      {renderAddonPrice(options[selected])}
      <ul id="options">
        {menuOptions.length > 1 && menuOptions.map(key => renderValueOption(key as any))}
      </ul>
      <div id="action">
        {renderCaptionText('action-text-desktop')}
        <Button
          as="button"
          size="large"
          color="red"
          onClick={() => {
            if (selected > 1) {
              setModal('confirm')
            } else {
              attachAddon()
            }
          }}
          loading={loading}
          fluid
        >
          {activateNow ? 'Yes, Order with One-Click!' : 'Yes, Add to My Free Trial'}
        </Button>
        <Button
          className={'no-hover'}
          as="button"
          size="large"
          onClick={toggle}
          disabled={loading}
          fluid
        >
          No thanks, I don’t want the additional protection.
        </Button>
        <SimpleBox as={'p'} color={'#787E8D'} marginTop={'20px'} fontSize={'12px'}>
          By clicking order you understand and agree that you will be{' '}
          billed {toMoney(selectedDiscountedTotalPrice)}/{toPlanPeriod(options[selected])}
          {!activateNow && ' at the end of your trial'}. If you opted in to our 3 months free promotion,{' '}
          you will be billed today and granted an additional 3 months free of VPN Plus.{' '}
          To ensure uninterrupted access, your subscription will automatically renew at the{' '}
          end of each billing cycle using the payment method provided. You can cancel{' '}
          and disable auto-renewal at any time.{' '}
          Our standard <a href={'https://virtualshield.com/legal/terms/'}>Terms of Service</a> still apply.
        </SimpleBox>
      </div>
    </section></>
  )
}

export default AddonPromotion
