import React, {FC, useEffect, useRef, useState} from 'react'
import {observer} from '../../decorators'
import {useNavigation} from '../../hooks/useNavigation'
import Router from '../../shared/router'
import {SimpleBox} from '../../components/SimpleBox'
import {gql, useMutation, useQuery} from '@apollo/client'
import {Plan, PlanSellingPoint} from '../../type'
import {CouponData} from '../../utils/coupon'
import {Points} from '../../components/Plans/components/Single/components'
import {Plans} from '../../components/Plans'
import {Breakdown} from '../../fragments/payment/Breakdown'
import {toMoney} from '../../shared/format'
import {ActivateNow} from '../../fragments/ActivateNow'
import {Selection} from '../../components/Plans/components/Selection'
import {set} from 'dot-prop'
import {SimpleCard} from '../../components/SimpleCard'
import {Form, FormField} from 'semantic-ui-react'
import {SimpleForm} from '../../components/SimpleForm'
import {Pane, SimpleTab} from '../../components/SimpleTab'
import {Submit} from '../../fragments/payment/Submit'
import {SimpleText} from '../../components/SimpleText'
import {PaymentData} from '../../fragments/payment/ICardProps'
import {CardComponent} from '../../fragments/payment/CardComponent'
import {Upsell} from '../../fragments/payment/Upsell'
import Cookies from 'universal-cookie'
import {useSiteSetting} from '../../hooks/useSiteSetting'
import {trackBeginCheckout, trackEmailValidationSuccess, trackPurchase} from '../../utils/tracking'
import {emailRegex} from '../../const'

const styles = require('./styles.module.scss')
const cookies = new Cookies()

type Result = { plan: Plan }

const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')
const PaymentPayPalIcon = require('~assets/images/icons/payment-paypal.svg')
const PaymentCardsImage = require('~assets/images/payment-cards.svg')

const PlanSellingPoints = (trialEnabled: boolean, isOne: boolean, isPlus: boolean, isFamily: boolean) => {
  const points: PlanSellingPoint[] = isPlus ? [
    { icon: PlanCheckMarkIcon, title: 'Unlimited Devices & Bandwidth' },
    { icon: PlanCheckMarkIcon, title: 'Malware Blocking' },
    { icon: PlanCheckMarkIcon, title: 'Ad Blocking' },
    { icon: PlanCheckMarkIcon, title: 'Unrestricted Streaming' },
    { icon: PlanCheckMarkIcon, title: 'Priority Phone Support' },
    { icon: PlanCheckMarkIcon, title: 'SSN Monitoring with Real-Time Alerts' },
  ] : [
    { icon: PlanCheckMarkIcon, title: `${trialEnabled ? '30' : '60'} Day Money Back Guarantee` },
    { icon: PlanCheckMarkIcon, title: '24/7 Helpful Support' },
    { icon: PlanCheckMarkIcon, title: 'Unlimited Devices' },
    { icon: PlanCheckMarkIcon, title: 'VPN', subtitle: 'Internet Security' }
  ]

  if(trialEnabled) {
    points.unshift({ icon: PlanCheckMarkIcon, title: '30 Day Free Trial' })
  }

  if(isOne) {
    points.push({icon: PlanCheckMarkIcon, title: 'VPN+', subtitle: 'Ad & Malware blocking, more'})
    points.push({icon: PlanCheckMarkIcon, title: 'ID Monitoring', subtitle: 'Identity Security'})
    points.push({icon: PlanCheckMarkIcon, title: '$1 million insurance'})
    points.push({icon: PlanCheckMarkIcon, title: 'Dark web scanning'})
    points.push({icon: PlanCheckMarkIcon, title: 'All new features and more!'})
    points.push({icon: PlanCheckMarkIcon, title: isFamily ? '5 users' : '1 user'})
  } else {
    points.push({icon: PlanCheckMarkIcon, title: isFamily ? '5 users' : '1 user'})
  }

  return points
}

const useCoupon = () => {
  const [code, setCode] = useState<string>('')
  const [submit, { data }] = useMutation<CouponData>(gql`
    mutation($code: String!, $priceId: ID) {
      coupon: redeemCoupon(code: $code, itemPriceId: $priceId) {
        id
        name
        discountType
        discountPercentage
        discountAmount
      }
    }
  `)

  useEffect(() => {
    const couponCode = cookies.get('coupon') || Router.qs.coupon
    if(couponCode) {
      setCode(couponCode as string)
    }
  }, [Router.qs.coupon, location.search])

  useEffect(() => {
    if (code) {
      submit({variables: {code: code}, fetchPolicy: 'no-cache'})
    }
  }, [code])

  return data?.coupon
}

const usePlan = (trialEnabled: boolean, priceId: string) => {
  const paypalData = (Router.qs.paypal as string || '').split('|')

  const coupon = useCoupon()
  const [activateNow, setActivateNow] = useState<boolean>(!trialEnabled || paypalData[2] === '1')

  const { data, loading } = useQuery<Result>(
    gql`
      query ($id: ID!) {
        plan(id: $id) {
          id
          name
          externalName
          description
          price
          period
          periodUnit
          pricingModel
          currencyCode
          trialPeriod
          trialPeriodUnit
          billingCycles
          freeQuantity
          itemFamily { name }
          item { metadata }
          __typename
        }
      }
    `,
    { variables: { id: priceId } }
  )

  const discount = () => {
    if (!coupon?.discountType) {
      return 0
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return Number(coupon.discountAmount)
    } else {
      return (data?.plan.price || 0) * Number(coupon.discountPercentage)
    }
  }

  const plan = data?.plan
  const isFamily = plan?.name.toLowerCase().includes('family') || false
  const months = (plan?.period || 0) * (plan?.periodUnit === 'YEAR' ? 12 : 1)
  const isOne = plan?.item?.metadata?.product === 'one'
  const isPlus = plan?.item?.metadata?.product === 'vpn-plus'
  const priceTotal = (plan?.price || 0) - discount()

  return {
    plan: data?.plan,
    loading,
    isFamily,
    months,
    isOne,
    isPlus,
    priceTotal,
    coupon,
    trialEnabled,
    activateNow,
    setActivateNow
  }
}

const SimpleCheckoutProduct: FC & { authorize: boolean; auth: string; } = (props: { children?: React.ReactNode, trialEnabled: boolean, promotion: boolean }) => {
  const forceAuthorizeOne = useSiteSetting('FORCE_AUTHORIZE_ON_ONE_PLANS')
  const plan = usePlan(props.trialEnabled && Router.qs.noTrial === undefined, Router.params.price as string)
  const { setNav, resetNav } = useNavigation()
  const paypalData = (Router.qs.paypal as string || '').split('|')
  const cardRef = useRef<any>()
  const captchaRef = useRef<any>()
  const [cardErrors, setCardErrors] = useState<string[]>([])
  const [type, setType] = useState<'CARD' | 'PAYPAL'>(Router.qs.paypal ? 'PAYPAL' : 'CARD')
  const [data, setData] = useState<PaymentData>({
    email: ((Router.qs.email as string) || paypalData[0] || '').replace(/ /g, '+'),
    firstName: '',
    lastName: '',
    zip: '',
    card: { number: '', expiry: '', cvv: '' },
    paypal: {},
    captcha: false,
  })

  const points = PlanSellingPoints(plan.trialEnabled, plan.isOne, plan.isPlus, plan.isFamily)
  plan.activateNow && points.shift()

  useEffect(() => {
    setNav('no-sign-in')
    return () => {
      resetNav()
    }
  }, [])

  const handleDataChange = ({ name, value }: { name: string; value: any }) => {
    setData(d => ({ ...set(d, name, value) }))
  }

  const handleEmailChange = (value: string) => {
    const regex = emailRegex
    const isValid = regex && regex.test(value)
    if (isValid) {
      trackEmailValidationSuccess()
    }

    setData(d => ({ ...set(d, 'email', value) }))
  }

  const handleTabChange = (index: number) => {
    setType(index === 0 ? 'CARD' : 'PAYPAL')
  }

  const onChangePlan = (item: Plan) => {
    const path = location.pathname.split('/')[1]
    Router.redirect( plan.coupon ? `/${path}/${item.id}?coupon=${plan.coupon.id}` : `/${path}/${item.id}`)
  }

  useEffect(() => {
    if (plan && plan.plan && !Router.qs.coupon) {
      trackBeginCheckout(plan.plan, plan.isFamily, plan.coupon, 'plan')
    }
  }, [plan.plan])

  useEffect(() => {
    if (plan && plan.plan && Router.qs.coupon) {
      trackBeginCheckout(plan.plan, plan.isFamily, plan.coupon, 'plan')
    }
  }, [plan.coupon])

  const paymentPanes: Pane[] = [
    {
      type: 'CARD',
      icon: PaymentCardsImage,
      title: 'Credit Card',
      render: <CardComponent
        data={data}
        forwardRef={cardRef}
        captchaRef={captchaRef}
        onChange={handleDataChange}
        onErrors={setCardErrors}
        forceAuthorize={plan.isOne && forceAuthorizeOne.enabled}
      />
    },
    {
      type: 'PAYPAL',
      icon: PaymentPayPalIcon,
      title: 'PayPal',
      render: <></>,
    },
  ]

  const activateNowMarkup = plan.trialEnabled && <ActivateNow
    months={plan.months}
    activateNow={plan.activateNow}
    setActivateNow={plan.setActivateNow}
  />

  const breakdownMarkup = (
    <Breakdown
      total={toMoney(plan.priceTotal)}
      price={plan.plan?.price || 0}
      coupon={plan.coupon}
      activateNow={plan.activateNow}
    />
  )

  const linkMarkup = (path: string, text: string) => (
    <a href={`https://virtualshield.com/${path}`} target="_blank" rel="noreferrer"> {text}</a>
  )

  const buttonDisabled = type === 'CARD' && cardErrors.length > 0

  const formMarkup = () => plan.plan ? (<><SimpleBox className={'checkout'}>
    <Form name={'checkout'}>
      <SimpleCard fluid padding="heavy">
        <FormField>
          <label htmlFor="email">Enter email address</label>
          <SimpleForm.Input
            type="email"
            value={data.email}
            onChange={(d: any) => handleEmailChange(d.value)}
            size="large"
            required
            fluid
            showValidation={true}
          />
          <div className="text--x-small"><span className="text">Privacy Guarantee:</span> We do not share your
                   information and will contact you only as needed to provide our service.</div>
        </FormField>
      </SimpleCard>
      <SimpleTab
        onChange={handleTabChange}
        panes={paymentPanes}
        initial={type === 'CARD' ? 0 : 1}
        footer={
          <>
            {plan.months && plan.months > 1 && plan.trialEnabled && (
              <SimpleBox>
                <SimpleText size="title2">Bonus Offer</SimpleText>
                {activateNowMarkup}
              </SimpleBox>) }
            <SimpleBox mt={3} className="submit-box">
              <Submit
                paypalReturnPath={`/${location.pathname.split('/')[1]}`}
                type={type}
                data={data}
                priceId={plan.plan?.id}
                cardRef={cardRef}
                coupon={plan.coupon}
                disabled={buttonDisabled}
                activateNow={plan.activateNow}
                promotion={plan.activateNow && props.promotion}
                captchaRef={captchaRef}
                purchaseFinished={() => plan && plan.plan && trackPurchase(plan.plan, plan.isFamily, plan.activateNow, plan.coupon)}
              />
            </SimpleBox>
            <SimpleBox mt={3}>
              <div className="text--x-small">
                  By signing up for our service, you agree to our
                {linkMarkup('legal/terms/', 'Terms of Service')} and acknowledge our
                {linkMarkup('legal/privacy/', 'Privacy Policy')}. To ensure uninterrupted access, your
                  subscription will automatically renew at the end of each billing cycle using the payment
                  method provided until you cancel it by disabling auto-renewal,
                  which can be done at any time.
              </div>
            </SimpleBox>
          </>
        }
      />
    </Form>
  </SimpleBox>
  </>
  ) : ''

  return (
    <>
      <div className={`${styles.container} checkout`}>
        <h1 className={styles.header}>
          <b>Final step</b> - It only takes a minute to enable your protection plan.
        </h1>
        <div className={styles.body}>
          <div className={styles.content}>
            <h2 className={styles.title}>Choose your preferred billing rate</h2>
            {plan.plan && <Selection plan={plan.plan} expanded onChangePlan={onChangePlan} coupon={plan.coupon} />}
            {plan.plan && (<Upsell currentPlan={plan.plan} onUpgrade={onChangePlan} coupon={plan.coupon} />)}
            <h2 className={styles.title}>Enter details and complete your order</h2>
            {formMarkup()}
          </div>
          <div className={styles.sidebar}>
            <h2 className={styles.title}>Order Summary</h2>
            {breakdownMarkup}
            <h2 className={styles.title}>Your plan includes</h2>
            <div className={styles.pointsContainer}>
              <Points items={points} secondary={true} footer={<></>} />
            </div>
            <Plans.Trial trialEnabled={!plan.activateNow && plan.trialEnabled} />
          </div>
        </div>
        <div className={styles.footer}>
        </div>
      </div>
    </>
  )
}

SimpleCheckoutProduct.defaultProps = {
  trialEnabled: true,
  promotion: true
}

SimpleCheckoutProduct.authorize = false
SimpleCheckoutProduct.auth = '/apps'

export default observer(SimpleCheckoutProduct)
