/* eslint-disable complexity */
import { createElement, CSSProperties, FC, ReactHTML } from 'react'

export type SimpleBoxProps = {
  as?: keyof ReactHTML
  title?: string
  name?: string
  className?: string
  m?: number | 'auto'
  mx?: number | 'auto'
  my?: number | 'auto'
  ml?: number | 'auto'
  mt?: number | 'auto'
  mr?: number | 'auto'
  mb?: number | 'auto'
  p?: number | string
  pt?: number
  pb?: number
  pl?: number
  pr?: number
  px?: number
  py?: number
} & CSSProperties
const SimpleBox: FC<SimpleBoxProps> = ({
  as,
  m,
  mx,
  my,
  ml,
  mt,
  mr,
  mb,
  p,
  px,
  py,
  pl,
  pt,
  pr,
  pb,
  children,
  className,
  title,
  name,
  ...other
}) => {
  const spacing = 'var(--spacing)'
  const style: CSSProperties = other
  if (m) {
    style.margin = typeof m === 'string' ? m : `calc(${m} * ${spacing})`
  }
  if (typeof ml === 'number') {
    style.marginLeft = `calc(${ml} * ${spacing})`
  } else if (ml) {
    style.marginLeft = ml
  }
  if (mt && mt !== 'auto') {
    style.marginTop = `calc(${mt} * ${spacing})`
  } else if (mt) {
    style.marginTop = mt
  }
  if (mr && mr !== 'auto') {
    style.marginRight = `calc(${mr} * ${spacing})`
  } else if (mr) {
    style.marginRight = mr
  }
  if (mb && mb !== 'auto') {
    style.marginBottom = `calc(${mb} * ${spacing})`
  }
  if (typeof mx === 'number') {
    style.marginLeft = `calc(${mx} * ${spacing})`
    style.marginRight = `calc(${mx} * ${spacing})`
  } else if (mx) {
    style.marginLeft = mx
    style.marginRight = mx
  }
  if (my) {
    style.marginTop = `calc(${my} * ${spacing})`
  }
  if (my) {
    style.marginBottom = `calc(${my} * ${spacing})`
  }
  if (p) {
    style.padding = typeof p === 'string' ? p : `calc(${p} * ${spacing})`
  }
  if (pl) {
    style.paddingLeft = `calc(${pl} * ${spacing})`
  }
  if (pt) {
    style.paddingTop = `calc(${pt} * ${spacing})`
  }
  if (pr) {
    style.paddingRight = `calc(${pr} * ${spacing})`
  }
  if (pb) {
    style.paddingBottom = `calc(${pb} * ${spacing})`
  }
  if (px) {
    style.paddingLeft = `calc(${px} * ${spacing})`
  }
  if (px) {
    style.paddingRight = `calc(${px} * ${spacing})`
  }
  if (py) {
    style.paddingTop = `calc(${py} * ${spacing})`
  }
  if (py) {
    style.paddingBottom = `calc(${py} * ${spacing})`
  }

  return createElement(as ?? 'div', { className, children, style, title, name })
}

SimpleBox.defaultProps = { display: 'inherit' }

export { SimpleBox }
