import React, { FC } from 'react'
import { Label, Table } from 'semantic-ui-react'
import { SimpleBox } from '../../components/SimpleBox'
import { SimpleCard } from '../../components/SimpleCard'
import { Coupon } from '../../utils/coupon'
import { toMoney, toPercent } from '../../shared/format'

type Props = {
  total: string
  price: number
  coupon?: Coupon
  activateNow: boolean
}

const Breakdown: FC<Props> = ({ total, price, coupon, activateNow }) => {
  const couponDiscount = (): string => {
    if (!coupon?.discountType) {
      return toMoney(0)
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return toMoney(coupon.discountAmount || 0)
    } else {
      return toPercent(coupon.discountPercentage || 0, 0)
    }
  }

  const couponValue = (): string => {
    if (!coupon?.discountType) {
      return toMoney(0)
    } else if (coupon.discountType === 'FIXED_AMOUNT') {
      return toMoney(coupon.discountAmount || 0)
    } else {
      return toMoney(price * (coupon.discountPercentage || 0))
    }
  }

  return (
    <SimpleCard outline fluid>
      <Table id="breakdown" basic="very" className="borderless" unstackable compact>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Selected Plan</Table.Cell>
            <Table.Cell textAlign="right">
              <div className="text">{toMoney(price)}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Discount
              {coupon && (
                <SimpleBox display="flex" alignItems="center" gap={12} className="text green-text">
                  Coupon added{' '}
                  <Label className="pill" color="green" size="tiny">
                    {couponDiscount()}
                  </Label>
                </SimpleBox>
              )}
            </Table.Cell>
            <Table.Cell textAlign="right">
              <SimpleBox display="flex" alignItems="center" justifyContent="flex-end" gap={12}>
                <div className="text">
                  {coupon ? '-' : ''}
                  {couponValue()}
                </div>
              </SimpleBox>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Subscription Total</Table.Cell>
            <Table.Cell textAlign="right">
              <div className="text">{total}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="text text--large">Total Due Today</Table.Cell>
            <Table.Cell textAlign="right">
              <div className="text text--x-large weight-bold">
                {activateNow ? total : '$0.00'}
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </SimpleCard>
  )
}
export { Breakdown }
