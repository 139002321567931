
import React, { VFC } from 'react'
const styles = require('./BackButton.module.scss')

/**
 * Properties for the back button
 */
type Props = {
  // A callback to call when users presses the button
  onBackPressed: () => void
}

// The back button icon
const backIcon = require('../../assets/images/icons/icon-arrow-back.svg')

/**
 * Renders a back button
 *
 * @param onBackPressed A callback to call when users presses the button
 * @param className Extra class names to apply to the button
 * @returns JSXElement
 */
const BackButton: VFC<Props & React.HTMLAttributes<HTMLAnchorElement>> = ({onBackPressed, className}) => <a
  onClick={onBackPressed}
  className={`${styles.BackButton} ${className}`}><img src={backIcon} /></a>

export { BackButton }
