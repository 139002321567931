import React, {FC, useEffect} from 'react'
import Router from './router'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

/**
 * Helper component to watch for ?ref query params.
 *
 * @param children
 * @constructor
 */
const AffiliateWatcher: FC = ({children}) => {
  useEffect(() => {
    if (Router.qs.aff) {
      cookies.set('affiliateId',
        Router.qs.aff,
        {path: '/', maxAge: 60 * 60 * 24 * 7, domain: `${window.location.hostname.replace('my', '')}`})
    }
  }, [Router.qs.aff])

  useEffect(() => {
    if (Router.qs.cid) {
      cookies.set('clickId',
        Router.qs.cid,
        {path: '/', maxAge: 60 * 60 * 24 * 7, domain: `${window.location.hostname.replace('my', '')}`})
    }
  }, [Router.qs.cid])

  useEffect(() => {
    if (Router.qs.pubid) {
      cookies.set('pubId',
        Router.qs.pubid,
        {path: '/', maxAge: 60 * 60 * 24 * 7, domain: `${window.location.hostname.replace('my', '')}`})
    }
  }, [Router.qs.pubid])

  return <>{children}</>
}

export default AffiliateWatcher

