import CheckoutProduct from '../checkout/[price]'
import React, {FC} from 'react'
import {observer} from '../../decorators'

const CheckoutNoTrial: FC & { authorize: boolean; auth: string; } = () => (
  // @ts-ignore
  <CheckoutProduct trialEnabled={false} promotion={false} />
)

CheckoutNoTrial.authorize = false
CheckoutNoTrial.auth = '/apps'

export default observer(CheckoutNoTrial)
