import { action, observer } from '@decorators'
import { useToggle } from '@shopify/react-hooks'
import React, { useEffect, useState, VFC } from 'react'
import { Form, FormField, Header, Image, Loader, Container } from 'semantic-ui-react'
import { gql, useLazyQuery } from '@apollo/client'
import Mutation from '~shared/mutation'
import Notifications from '~shared/notifications'
import Router from '~shared/router'
import Session from '~shared/storages/session'
import { SimpleBox } from '../components/SimpleBox'
import { SimpleCard } from '../components/SimpleCard'
import { SimpleLayout } from '../components/SimpleLayout'
import AddonPromotion from '../fragments/AddonPromotion'
import { SimplePasswordInput } from '../components/SimplePasswordInput/SimplePasswordInput'
import { useNavigation } from '../hooks/useNavigation'
import { Settings } from '../config'
import {Item} from '../shared/types'

const PROMOTION_ITEM = Settings.welcomePromotionItem
const SuccessImage = require('~assets/images/success.png')

type ItemPrice = { itemId: string, itemType: string, period: number, periodUnit: string, item: Item }
type Account = { subscriptions: { id: string, itemPrices: ItemPrice[], items: Item[] }[] }
export type Addon = { id: string, period: number, periodUnit: string, price: number }
export type Addons = { addons: Addon[], account: Account }

type Result = { createPassword: boolean }

function hasAddon(id: string, account: Account): boolean {
  return !!account.subscriptions[0]?.itemPrices.find(item => (
    item.itemId === id
  ))
}

const Welcome: VFC = () => {
  const { setNav, resetNav, setSide, resetSide } = useNavigation()

  const [password, setPassword] = useState('')
  const [strength, setStrength] = useState(0)
  const [withAddon, setWithAddon] = useState<'loading' | 'visible' | 'hidden'>(PROMOTION_ITEM ? 'loading' : 'hidden')

  const loading = useToggle(false)

  const mutation = new Mutation<Result>('mutation($password: String!) { createPassword(password: $password) }')
  const [loadAddons, addons] = useLazyQuery<Addons>(gql`
    query($id: ID!) {
      addons(itemId: $id) { id period periodUnit price }
      account { 
      subscriptions { 
        id 
        items {
          metadata
        }
        itemPrices { 
          itemId
          itemType
          period
          periodUnit
        } 
       } 
      }
   }
  `, { fetchPolicy: 'no-cache' })

  if(PROMOTION_ITEM && withAddon === 'loading') {
    if(!addons.called) {
      loadAddons({ variables: { id: PROMOTION_ITEM } })
    } else if(!addons.loading && addons.data) {
      const show = (addons.data?.addons?.length && !hasAddon(PROMOTION_ITEM, addons.data.account))
      const isOne = (addons.data?.account.subscriptions[0]?.items[0]?.metadata?.product === 'one')
      const isPlus = (addons.data?.account.subscriptions[0]?.items[0]?.metadata?.product === 'vpn-plus')

      setWithAddon(show && !isOne && !isPlus ? 'visible' : 'hidden')
    }
  }

  const handleSubmit = () => {
    loading.setTrue()
    mutation.exec({ password }).then(
      action(() => {
        loading.setFalse()
        if (mutation.data?.createPassword) {
          Session.update({ hasPassword: true })
          Router.redirect('/apps')
        } else {
          Notifications.error(mutation.error())
        }
      })
    )
  }

  const handlePasswordChange = (v: string, s: number) => {
    setPassword(v)
    setStrength(s)
  }

  useEffect(() => {
    setNav('hidden')
    setSide('hidden')
    return () => {
      resetNav()
      resetSide()
    }
  }, [])

  if(withAddon === 'loading') {
    return (
      <SimpleLayout>
        <Container>
          <Loader active />
        </Container>
      </SimpleLayout>
    )
  } else if(withAddon === 'visible' && addons.data?.addons) {
    return <AddonPromotion data={addons.data} toggle={() => setWithAddon('hidden')} />
  }

  return (
    <SimpleLayout>
      <SimpleBox display="grid" alignItems="center" width="100%">
        <SimpleBox width={590} mx="auto" className="form set-password-form">
          <SimpleBox textAlign="center">
            <Image src={SuccessImage} height={200} width={200} style={{ margin: '0 auto' }} />
            <SimpleBox my={4}>
              <Header as="h4">Secure Your Account</Header>
            </SimpleBox>
            <SimpleBox mb={8}>
              <div className="text text--subdued text--large">
                Please create a password for your new account
                <br />
                <span className="text">{Session.profile?.email}</span>
              </div>
            </SimpleBox>
          </SimpleBox>
          <SimpleCard rounded="heavy" padding="heavy" fluid>
            <SimpleBox>
              <Form id="reset-password" onSubmit={handleSubmit}>
                <SimpleBox mt={2} mb={6}>
                  <FormField>
                    <label htmlFor="password">Password</label>
                    <SimplePasswordInput size="large" value={password} onChange={handlePasswordChange!} fluid />
                  </FormField>
                  <SimpleBox mt={2}>
                    <div>
                      Your password should contain at least 8 characters and include a combination of upper and lower case letters, numbers, and
                      symbols.
                    </div>
                  </SimpleBox>
                </SimpleBox>
                <SimpleBox maxWidth={276} mx="auto">
                  <Form.Button
                    color="red"
                    primary
                    disabled={strength < 2}
                    size="huge"
                    content="Create password"
                    fluid
                    loading={loading.value}
                  />
                </SimpleBox>
              </Form>
            </SimpleBox>
          </SimpleCard>
        </SimpleBox>
      </SimpleBox>
    </SimpleLayout>
  )
}

export default observer(Welcome)
