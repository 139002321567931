import { set } from 'mobx'
import { GraphQLError } from 'graphql'
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils'
import {
  gql,
  DocumentNode,
  QueryOptions,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client'

import { computed } from '@decorators'

import { GQLClient } from '#root/config'

type Client<T> = IPromiseBasedObservable<ApolloQueryResult<T>>
type Options = QueryOptions<OperationVariables, any>
type Variables = Hash | (() => Hash)

export default class Query<T> implements IGQLHandler<ApolloQueryResult<T>> {
  document: DocumentNode

  variables?: Variables

  gql: Client<T>

  static load<R>(request: DocumentNode | string, variables: Variables = {}): Query<R> {
    const query = new Query<R>(request, variables)
    query.load()
    return query
  }

  constructor(request: DocumentNode | string, variables?: Variables) {
    this.document = (typeof(request) === 'string') ? gql(request) : request
    this.variables = variables
  }

  get client(): Client<T> {
    return this.gql ? this.gql : (this.load() && this.gql)
  }

  @computed
  get loading() {
    return !this.client || this.client.state === 'pending'
  }

  @computed
  get loaded() {
    return this.client?.state === 'fulfilled'
  }

  @computed
  get result() {
    return this.client.value
  }

  @computed
  get status() {
    return this.client.state
  }

  @computed
  get errors() {
    return !this.loading ? this.result.errors : undefined
  }

  @computed
  get data(): T | undefined {
    return !this.loading ? this.result.data : undefined
  }

  @computed
  get hasErrors() {
    return this.hasNetworkError || (this.errors ? this.errors.length > 0 : false)
  }

  @computed
  get hasNetworkError() {
    return this.client.value.networkStatus === 8
  }

  load(variables?: Variables, settings: Partial<Exclude<Options, 'query'>> = {}): Client<T> {
    const readVars = variables || this.variables || {}
    const vars = (typeof(readVars) === 'function') ? readVars() : readVars
    this.variables = vars

    const options: Options = Object.assign({ query: this.document, variables: vars }, settings)
    return this.gql = fromPromise(GQLClient.query(options))
  }

  error(pos = 0): string | undefined {
    return (this.errors && this.errors.length > 0) ? (this.errors[pos] as GraphQLError)?.message : undefined
  }

  // TODO: Improve this functionality
  update(data: T): void {
    if(this.gql) {
      GQLClient.writeQuery({ query: this.document, data })
      set(this.gql, 'value', { ...this.result, data })
    }
  }
}
