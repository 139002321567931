import { Addon } from '../pages/welcome'
import { Plan } from '../type'
import { ChargebeeCoupon, Coupon } from './coupon'
import {toCouponPrice} from '../shared/format'

interface CustomWindow extends Window {
  dataLayer: any[];
}

declare const window: CustomWindow

const cacheKeys: string[] = []

/**
 * Track begin checkout event
 */
export const trackBeginCheckout = (
  plan: Plan,
  isFamily: boolean | undefined,
  coupon: Partial<ChargebeeCoupon> | undefined,
  tag: string
): void => {
  const cacheKey = `${plan.id}-${isFamily}-${coupon?.name}`
  if (cacheKeys.includes(cacheKey)) { return }
  cacheKeys.pop()
  cacheKeys.push(cacheKey)

  // eslint-disable-next-line no-console
  console.log('trackBeginCheckout', tag, plan, isFamily, coupon)

  const discount = Number(((plan.price - toCouponPrice(plan.price, coupon)) / plan.period).toFixed(2))
  window.dataLayer.push({ ecommerce: null })

  window.dataLayer.push({
    event: 'begin_checkout',
    account: isFamily ? 'family' : 'personal',
    subscription:
      plan.period === 24 ? '2 year' : plan.period === 12 ? 'yearly' : 'monthly',
    // eslint-disable-next-line camelcase
    product_name: plan.name,
    ecommerce: {
      currency: 'USD',
      value: plan.price,
      coupon: coupon?.name || null,
      items: [
        {
          // eslint-disable-next-line camelcase
          item_name: plan.externalName,
          // eslint-disable-next-line camelcase
          item_id: plan.id,
          price: (plan.price / plan.period).toFixed(2),
          // eslint-disable-next-line camelcase
          item_brand: 'virtual shield',
          // eslint-disable-next-line camelcase
          item_category: plan.item?.metadata?.product === 'one' ? 'one' : 'vpn',
          // eslint-disable-next-line camelcase
          item_category2: isFamily ? 'family' : 'personal',
          // eslint-disable-next-line camelcase
          item_category3:
            plan.period === 24
              ? '2 year'
              : plan.period === 12
                ? 'yearly'
                : 'monthly',
          quantity: plan.period,
          currency: 'USD',
          coupon: coupon?.name || null,
          discount: discount,
        },
      ],
    },
  })
}

/**
 * Track purchase event
 */
export const trackPurchase = (
  plan: Plan,
  isFamily: boolean | undefined,
  activateNow: boolean,
  coupon: Partial<ChargebeeCoupon> | undefined
): void => {
  // eslint-disable-next-line no-console
  console.log('trackPurchase', plan, isFamily, activateNow, coupon)
  if (!plan) { return }

  const discount = Number(((plan.price - toCouponPrice(plan.price, coupon)) / plan.period).toFixed(2))

  window.dataLayer.push({ ecommerce: null })

  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      account: isFamily ? 'family' : 'personal', // personal or family
      subscription:
        plan.period === 24
          ? '2 year'
          : plan.period === 12
            ? 'yearly'
            : 'monthly',
      // eslint-disable-next-line camelcase
      product_name: plan.name,
      // eslint-disable-next-line camelcase
      free_trial: !activateNow,
      currency: 'USD',
      value: plan.price,
      coupon: coupon?.name || null,
      // eslint-disable-next-line camelcase
      transaction_id: (new Date()).getTime(),
      items: [
        {
          // eslint-disable-next-line camelcase
          item_name: plan.externalName,
          // eslint-disable-next-line camelcase
          item_id: plan.id,
          price: (plan.price / plan.period).toFixed(2),
          // eslint-disable-next-line camelcase
          item_brand: 'virtual shield',
          // eslint-disable-next-line camelcase
          item_category: plan.item?.metadata?.product === 'one' ? 'one' : 'vpn',
          // eslint-disable-next-line camelcase
          item_category2: isFamily ? 'family' : 'personal',
          // eslint-disable-next-line camelcase
          item_category3:
            plan.period === 24
              ? '2 year'
              : plan.period === 12
                ? 'yearly'
                : 'monthly',
          quantity: plan.period,
          currency: 'USD',
          coupon: coupon?.name || null,
          discount: discount,
        },
      ],
    },
  })
}

export const trackOneClickPurchase = (addon: Addon, coupon?: Coupon): void => {
  // eslint-disable-next-line no-console
  console.log('trackOneClickPurchase', addon, coupon)

  window.dataLayer.push({ ecommerce: null })

  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      account: null,
      subscription:
        addon.period === 24
          ? '2 year'
          : addon.period === 12
            ? 'yearly'
            : 'monthly',
      // eslint-disable-next-line camelcase
      product_name: 'plus',
      // eslint-disable-next-line camelcase
      free_trial: null,
      currency: 'USD',
      value: addon.price,
      // eslint-disable-next-line camelcase
      transaction_id: (new Date()).getTime(),
      items: [
        {
          // eslint-disable-next-line camelcase
          item_name: 'VPN Plus monthly',
          // eslint-disable-next-line camelcase
          item_id: addon.id,
          price: (addon.price / addon.period).toFixed(2), // note that price & quantity adapts based on account & subscription
          // eslint-disable-next-line camelcase
          item_brand: 'virtual shield',
          // eslint-disable-next-line camelcase
          item_category: 'plus',
          // eslint-disable-next-line camelcase
          item_category2: null, // not applicable, personal or family
          // eslint-disable-next-line camelcase
          item_category3:
            addon.period === 24
              ? '2 year'
              : addon.period === 12
                ? 'yearly'
                : 'monthly',
          quantity: addon.period,
          currency: 'USD',
          coupon: coupon?.name || null,
          discount: 0,
        },
      ],
    },
  })
}

const virtualPageViews: string[] = []

export const trackVirtualPageView = (path: string, title: string, authId: string | null): void => {
  const lastElm = virtualPageViews[virtualPageViews.length - 1]
  if (lastElm === path) { return }

  virtualPageViews.push(path)

  // eslint-disable-next-line no-console
  console.log('trackVirtualPageView', path, title, authId)
  window.dataLayer.push({
    'event': 'virtual_page_view',
    'page_path': path,
    'page_title': title,
    'userId': authId,
    'userStatus': authId !== null ? 'logged-in' : 'logged-out',
  })
}

export const trackEmailValidationSuccess = (): void => {
  // eslint-disable-next-line no-console
  console.log('trackEmailValidationSuccess')
  window.dataLayer.push({
    'event': 'email_validation_success',
  })
}

export const trackCancelSubscription = (period: number, isFamily: boolean, isOne: boolean): void => {
  // eslint-disable-next-line no-console
  console.log('trackCancelSubscription', period, isFamily, isOne)
  window.dataLayer.push({
    'event': 'cancel_subscription',
    'account': isFamily ? 'family' : 'personal', // personal or family
    'subscription': period === 24
      ? '2 year'
      : period === 12
        ? 'yearly'
        : 'monthly',
    'product_name': isOne ? 'one' : 'vpn',
  })
}
