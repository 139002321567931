import React, { FC } from 'react'
import { Image } from 'semantic-ui-react'
import { SimpleBox } from '../../../SimpleBox'

const BadgeImage = require('~assets/images/badge-risk-free.svg')

const Trial: FC<{ trialEnabled: boolean }> = ({ trialEnabled }) => {
  // eslint-disable-next-line max-len
  const text = trialEnabled ? <>All VirtualShield plans come with a <span className="text">30 Day Free Trial</span> & <span className="text"> 30 Day Money-Back Guarantee</span> for complete peace of mind.</>
    : <>All VirtualShield plans come with a <span className="text">60 Day Money-Back Guarantee</span> for complete peace of mind.</>

  return (
    <SimpleBox className="plans-trial" display="flex" gap={12} py={3} pr={2} alignItems="start">
      <Image src={BadgeImage}/>
      <div className="text text--small text--subdued">
        {text}
      </div>
    </SimpleBox>
  )
}

export { Trial }
