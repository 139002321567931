/* eslint-disable @typescript-eslint/ban-ts-comment */
import { classNames, variationName } from '@shopify/css-utilities'
import { createElement, FC, ReactDOM } from 'react'

const styles = require('./SimpleText.module.scss')

type Props = {
  as?: keyof ReactDOM
  size?: 'small' | 'medium' | 'large' | 'extraLarge' | 'title1' | 'title2' | 'title3'
  weight?: 'light' | 'bold' | 'extraBold'
  className?: string
}
const SimpleText: FC<Props> = ({ children, as, size, weight, className }) => {
  const styleMain = className || classNames(
    styles.Main,
    size && styles[variationName('size', size)],
    weight && styles[variationName('weight', weight)]
  )
  return createElement(as ?? 'p', { children, className: styleMain })
}
export { SimpleText }
